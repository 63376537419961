import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import { createHead } from '@vueuse/head'
import Bootstrap3 from 'bootstrap-vue-3'
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons';
//import vSelect from 'vue-select'
import store from './store'
import router from './router'
import App from './App.vue'
import FactoryRepository from '@/api/FactoryRepository'
import '@/libs/toastification'
import JWT from '@/auth/JWT'
import helperFunctions from '@/libs/helper'
//import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

const app = createApp(App)
app.use(Bootstrap3)
app.component('BootstrapIcon', BootstrapIcon);
app.use(router)
app.use(store)

const head = createHead()
app.use(head)

/* language files */
import textEn from '@/locales/text-en.json'
import textSk from '@/locales/text-sk.json'

const messages = {
  en: textEn,
  sk: textSk
}
const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
})
app.use(i18n)

const helper = {
  install() {
    app.helper = helperFunctions
    app.config.globalProperties.$helper = helperFunctions
  },
}
app.use(helper)

// JWT
const Jwt = {
  install() {
    app.JWT = JWT
    app.config.globalProperties.$JWT = JWT
  },
}
app.use(Jwt)

// API Factory
FactoryRepository.init(JWT)
const api = {
  install() {
    app.Articles = FactoryRepository.get('Articles')
    app.config.globalProperties.$Articles = FactoryRepository.get('Articles')

    app.Coupons = FactoryRepository.get('Coupons')
    app.config.globalProperties.$Coupons = FactoryRepository.get('Coupons')

    app.Enums = FactoryRepository.get('Enums')
    app.config.globalProperties.$Enums = FactoryRepository.get('Enums')

    app.Exports = FactoryRepository.get('Exports')
    app.config.globalProperties.$Exports = FactoryRepository.get('Exports')

    app.Files = FactoryRepository.get('Files')
    app.config.globalProperties.$Files = FactoryRepository.get('Files')

    app.MealPlans = FactoryRepository.get('MealPlans')
    app.config.globalProperties.$MealPlans = FactoryRepository.get('MealPlans')

    app.Navigations = FactoryRepository.get('Navigations')
    app.config.globalProperties.$Navigations = FactoryRepository.get('Navigations')

    app.Orders = FactoryRepository.get('Orders')
    app.config.globalProperties.$Orders = FactoryRepository.get('Orders')

    app.Resorts = FactoryRepository.get('Resorts')
    app.config.globalProperties.$Resorts = FactoryRepository.get('Resorts')

    app.Rooms = FactoryRepository.get('Rooms')
    app.config.globalProperties.$Rooms = FactoryRepository.get('Rooms')

    app.RoomVariants = FactoryRepository.get('RoomVariants')
    app.config.globalProperties.$RoomVariants = FactoryRepository.get('RoomVariants')

    app.Users = FactoryRepository.get('Users')
    app.config.globalProperties.$Users = FactoryRepository.get('Users')

    app.WebPages = FactoryRepository.get('WebPages')
    app.config.globalProperties.$WebPages = FactoryRepository.get('WebPages')

    app.Emails = FactoryRepository.get('Emails')
    app.config.globalProperties.$Emails = FactoryRepository.get('Emails')

    app.EmailTemplates = FactoryRepository.get('EmailTemplates')
    app.config.globalProperties.$EmailTemplates = FactoryRepository.get('EmailTemplates')

    app.Pdfs = FactoryRepository.get('Pdfs')
    app.config.globalProperties.$Pdfs = FactoryRepository.get('Pdfs')

    app.Variables = FactoryRepository.get('Variables')
    app.config.globalProperties.$Variables = FactoryRepository.get('Variables')
  },
}
app.use(api)
app.mount('#app')
