<template>
  <div>
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>
<script>
import LayoutHorizontal from "@/layouts/LayoutHorizontal.vue";
import LayoutFull from "@/layouts/LayoutFull.vue";
import LayoutVertical from "@/layouts/LayoutVertical.vue";
import { computed } from 'vue'
import { useHead } from '@vueuse/head'

export default {
  components: {
    LayoutFull,
    LayoutHorizontal,
    LayoutVertical,
  },
  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return "layout-vertical";
    },
  },
  created() {
    useHead({
      
      title: computed(() => {
        let title = ''
        if(this.$route.meta.pageTitle) {
          title = this.$t(this.$route.meta.pageTitle)
        }
        title += ' - ' + this.$store.state.appName
        return title
      
      }),
      
    })
  }

};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";
</style>
