import Resource from './Resource'

const apiURL = `https://${process.env.VUE_APP_API_URL}`
let repositories = {}

export default {
  init: JWT => {
    repositories = {
      Articles: new Resource(JWT, { apiURL, resource: 'articles' }),
      Categories: new Resource(JWT, { apiURL, resource: 'categories' }),
      Coupons: new Resource(JWT, { apiURL, resource: 'coupons' }),
      Enums: new Resource(JWT, { apiURL, resource: 'enums' }),
      Files: new Resource(JWT, { apiURL, resource: 'files' }),
      MealPlans: new Resource(JWT, { apiURL, resource: 'meal_plans' }),
      Navigations: new Resource(JWT, { apiURL, resource: 'navigations' }),
      Orders: new Resource(JWT, { apiURL, resource: 'orders' }),
      Resorts: new Resource(JWT, { apiURL, resource: 'resorts' }),
      Rooms: new Resource(JWT, { apiURL, resource: 'rooms' }),
      RoomVariants: new Resource(JWT, { apiURL, resource: 'room_variants' }),
      Users: new Resource(JWT, { apiURL, resource: 'users' }),
      WebPages: new Resource(JWT, { apiURL, resource: 'web_pages' }),
      Emails: new Resource(JWT, { apiURL, resource: 'emails' }),
      EmailTemplates: new Resource(JWT, { apiURL, resource: 'email_templates' }),
      Pdfs: new Resource(JWT, { apiURL, resource: 'pdfs' }),
      Variables: new Resource(JWT, { apiURL, resource: 'environment_variables' }),
      Exports: new Resource(JWT, { apiURL, resource: 'exports' }),
    }
  },
  get: name => repositories[name],
}
