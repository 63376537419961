<template>
  <div :class="{ active: navbarActive }"  id="main-navigation">
    <a href="javascript:void(0)" class="navbar-close" @click="closeNav">
       <BootstrapIcon
            icon="x-lg"
            size="1x"
          />
    </a>
    <router-link to="/" class="logo">
      <img width="180" src="@/assets/images/logo.svg" alt="logo" />
    </router-link>
    <div class="navigation-container">
      <!-- Main navigation links -->
      <nav id="navigation-links">
        <router-link to="/resorts" @click="closeNav" v-if="isGranted('resort')">
          <BootstrapIcon
            icon="building"
            size="1x"
          />
          {{ $t('navigations.resorts') }}
        </router-link>
        <router-link to="/orders" @click="closeNav" v-if="isGranted('order')">
          <BootstrapIcon
            icon="bag"
            size="1x"
          />
          {{ $t('navigations.orders') }}
        </router-link>
        <router-link to="/abandoned-orders" @click="closeNav" v-if="isGranted('order')">
          <BootstrapIcon
            icon="bag-x"
            size="1x"
          />
          Abandoned orders
        </router-link>
        <router-link to="/" @click="closeNav" v-if="isGranted('user')">
          <BootstrapIcon
            icon="people"
            size="1x"
          />
          {{ $t('navigations.customers') }}
        </router-link>
        <router-link to="/employees" @click="closeNav" v-if="isGranted('admin')">
          <BootstrapIcon
            icon="briefcase"
            size="1x"
          />
          {{ $t('navigations.employees') }}
        </router-link>
        <router-link to="/invoices" @click="closeNav" v-if="isGranted('order')">
          <BootstrapIcon
            icon="file-earmark-text"
            size="1x"
          />
          {{ $t('navigations.invoices') }}
        </router-link>
        <router-link to="/vouchers" @click="closeNav" v-if="isGranted('order')">
          <BootstrapIcon
            icon="file-earmark-image"
            size="1x"
          />
          Vouchers
        </router-link>
        <router-link to="/coupons" @click="closeNav" v-if="isGranted('coupon')">
          <BootstrapIcon
            icon="percent"
            size="1x"
          />
          {{ $t('navigations.coupons') }}
        </router-link>
        <div class="separator"></div>
        <router-link to="/pages" @click="closeNav" v-if="isGranted('webpage')">
          <BootstrapIcon
            icon="book"
            size="1x"
          />
          {{ $t('navigations.pages') }}
        </router-link>
        <router-link to="/articles" @click="closeNav" v-if="isGranted('article')">
          <BootstrapIcon
            icon="vector-pen"
            size="1x"
          />
          {{ $t('navigations.articles') }}
        </router-link>
        <router-link to="/navigations" @click="closeNav" v-if="isGranted('navigation')">
          <BootstrapIcon
            icon="send"
            size="1x"
          />
          {{ $t('navigations.navigation') }}
        </router-link>
        <router-link to="/emails" @click="closeNav" v-if="isGranted('email')">
          <BootstrapIcon
            icon="envelope-open"
            size="1x"
          />
          {{ $t('navigations.emails') }}
        </router-link>
        <router-link to="/email/templates" @click="closeNav" v-if="isGranted('email_template')">
          <BootstrapIcon
            icon="envelope"
            size="1x"
          />
          {{ $t('navigations.emailTemplates') }}
        </router-link>
        <router-link to="/variables" @click="closeNav" v-if="isGranted('variable')">
          <bootstrap-icon
            icon="gear"
            size="1x"
          />
          {{ $t('forms.settings') }}
        </router-link>
        <router-link to="/exports" @click="closeNav">
          <bootstrap-icon
            icon="file-arrow-down"
            size="1x"
          />
          {{ $t('navigations.exports') }}
        </router-link>
        <router-link to="/logout" @click="closeNav">
          <BootstrapIcon
            icon="box-arrow-right"
            size="1x"
          />
          {{ $t('navigations.logout') }}
        </router-link>
      </nav>
    </div>
  </div>
</template>

<script>
import {getUserData, getUserRoles} from "@/auth/utils";

export default {
  name: "NavBar",
  props: {
    navbarActive: { type: Boolean, default: false }
  },
  data() {
    return {
      user: getUserData(),
      roles: getUserRoles(),
    }
  },
  methods: {
    isGranted(action) {
      let role = ('ROLE_PRIVILEGE_'+action).toUpperCase()
      return this.roles && (this.roles.includes(role) || this.roles.includes('ROLE_ADMIN'))
    },
    closeNav() {
      this.$emit('navToggle', false)
    },
    getActiveClass() {
      let className = ''
      if (this.navbarActive === true) {
        className = 'active'
      }
      return className
    }
  }
};
</script>
