import JWT from './JWT'

export const isUserLoggedIn = () => localStorage.getItem('userData') && localStorage.getItem(JWT.config.storageTokenKeyName)

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const getUserRoles = () => JSON.parse(localStorage.getItem('userData')).roles

export const getHomeRouteForLoggedInUser = userRole => {

  if (userRole === 'ROLE_EMPLOYEE') {
    const userRoles = getUserRoles()
    let route = null
    userRoles.forEach(role => {
      if (role.startsWith('ROLE_PRIVILEGE_')) {
        let action = (role.replace('ROLE_PRIVILEGE_', '')).toLowerCase()
        if (null === route) {
          route = `/${action}s`
        }
      }
    });

    if (null !== route) {
      return route
    }

    return `/`
  }

  if (userRole === 'ROLE_ADMIN') return `/`
  if (userRole === 'ROLE_USER') return `/`
  if (userRole === 'ROLE_CLIENT') return `/`

  return { name: 'login' }
}
