<template>
  <div class="navbar-top">
    <a href="javascript:void(0)" class="navbar-toggler" @click="showNav">
      <BootstrapIcon
          icon="list"
          size="1x"
        />
    </a>
    <!-- User circle info -->
    <router-link v-if="user" to="/profile" class="user">
      <b-list-group>
        <b-list-group-item class="user-circle">
          
          <div class="user-info">
            {{ user.givenName }} {{ user.familyName }}
            <span>{{ $helper.getEnumTranslation("roles", user.role, $i18n.locale) }}</span>
          </div>
          <b-avatar
            badge
            class="user-photo"
            variant="primary"
            icon="star-fill"
            :src="user.fileUrl ? user.fileUrl : ''"
            badge-variant="success"
          />
        </b-list-group-item>
      </b-list-group>
    </router-link>
  </div>
</template>

<script>
import { BAvatar, BListGroup, BListGroupItem } from "bootstrap-vue-3";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BAvatar,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      user: getUserData(),
    }
  },
  methods: {
    showNav() {
      this.$emit('navToggle', true)
    }
  }
};
</script>

<style>
</style>