import {createRouter, createWebHistory} from 'vue-router'
import {isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser} from '@/auth/utils'

const routes = [
  // Logout
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/Logout.vue'),
    meta: {
      pageTitle: 'navigations.logout',
      layout: 'full',
    },
  },
  // Logout End
  // Login
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      pageTitle: 'navigations.login',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  // Login End
  // Emails
  {
    path: '/emails',
    name: 'Emails',
    component: () => import('@/views/Admin/Emails/list.vue'),
    meta: {
      pageTitle: 'navigations.emails',
      breadcrumb: [
        {
          text: 'navigations.emails',
          active: true,
        },
      ],
    },
  },
  {
    path: '/emails/:id',
    name: 'ViewEmails',
    component: () => import('@/views/Admin/Emails/detail.vue'),
    meta: {
      pageTitle: 'messages.viewEmail',
      breadcrumb: [
        {
          text: 'messages.viewEmail',
          active: true,
        },
      ],
    },
  },
  // Emails End
  // Email templates
  {
    path: '/email/templates',
    name: 'EmailTemplates',
    component: () => import('@/views/Admin/EmailTemplates/list.vue'),
    meta: {
      pageTitle: 'navigations.emailTemplates',
      breadcrumb: [
        {
          text: 'navigations.emailTemplates',
          active: true,
        },
      ],
    },
  },
  {
    path: '/email/templates/:id/edit',
    name: 'EditEmailTemplate',
    component: () => import('@/views/Admin/EmailTemplates/edit.vue'),
    meta: {
      pageTitle: 'messages.emailTemplateUpdate',
      breadcrumb: [
        {
          text: 'messages.emailTemplateUpdate',
          active: true,
        },
      ],
    },
  },
  // Email template end
  // Exports
  {
    path: '/exports',
    name: 'exports',
    component: () => import('@/views/Admin/Exports/index.vue'),
    meta: {
      pageTitle: 'navigations.exports',
      breadcrumb: [
        {
          text: 'navigations.exports',
          active: true,
        },
      ],
    },
  },
  // Exports End
  // Forgot Password
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      pageTitle: 'navigations.forgotPassword',
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  // Forgot Password End
  // Orders
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/Admin/Orders/list.vue'),
    meta: {
      pageTitle: 'navigations.orders',
      breadcrumb: [
        {
          text: 'navigations.orders',
          active: true,
        },
      ],
    },
  },
  {
    path: '/abandoned-orders',
    name: 'abandonedOrders',
    component: () => import('@/views/Admin/Orders/abandoned-list.vue'),
    meta: {
      pageTitle: 'Abandoned orders',
      breadcrumb: [
        {
          text: 'Abandoned orders',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-order',
    name: 'AddNewOrder',
    component: () => import('@/views/Admin/Orders/add.vue'),
    meta: {
      pageTitle: 'messages.orderNew',
      breadcrumb: [
        {
          text: 'messages.orderNew',
          active: true,
        },
      ],
    },
  },
  {
    path: '/orders/:id/edit',
    name: 'EditOrder',
    component: () => import('@/views/Admin/Orders/edit.vue'),
    meta: {
      pageTitle: 'messages.orderUpdate',
      breadcrumb: [
        {
          text: 'messages.orderUpdate',
          active: true,
        },
      ],
    },
  },
  // Orders end

  // Pages
  {
    path: '/pages',
    name: 'pages',
    component: () => import('@/views/Admin/Pages/list.vue'),
    meta: {
      pageTitle: 'navigations.pages',
      breadcrumb: [
        {
          text: 'navigations.pages',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pages/:id/edit',
    name: 'EditPage',
    component: () => import('@/views/Admin/Pages/edit.vue'),
    meta: {
      pageTitle: 'messages.pageUpdate',
      breadcrumb: [
        {
          text: 'messages.pageUpdate',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-page',
    name: 'AddNewPage',
    component: () => import('@/views/Admin/Pages/add.vue'),
    meta: {
      pageTitle: 'messages.pageNew',
      breadcrumb: [
        {
          text: 'messages.pageNew',
          active: true,
        },
      ],
    },
  },
  // Pages End
  // Articles
  {
    path: '/articles',
    name: 'articles',
    component: () => import('@/views/Admin/Articles/list.vue'),
    meta: {
      pageTitle: 'navigations.articles',
      breadcrumb: [
        {
          text: 'navigations.articles',
          active: true,
        },
      ],
    },
  },
  {
    path: '/articles/:id/edit',
    name: 'EditArticle',
    component: () => import('@/views/Admin/Articles/edit.vue'),
    meta: {
      pageTitle: 'messages.articleUpdate',
      breadcrumb: [
        {
          text: 'messages.articleUpdate',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-article',
    name: 'AddNewArticle',
    component: () => import('@/views/Admin/Articles/add.vue'),
    meta: {
      pageTitle: 'messages.articleNew',
      breadcrumb: [
        {
          text: 'messages.articleNew',
          active: true,
        },
      ],
    },
  },
  // Articles End
  // Navigations
  {
    path: '/navigations',
    name: 'navigations',
    component: () => import('@/views/Admin/Navigations/list.vue'),
    meta: {
      pageTitle: 'navigations.navigations',
      breadcrumb: [
        {
          text: 'navigations.navigations',
          active: true,
        },
      ],
    },
  },
  {
    path: '/navigations/:position/edit',
    name: 'EditNavigation',
    component: () => import('@/views/Admin/Navigations/edit.vue'),
    meta: {
      pageTitle: 'messages.navigationUpdate',
      breadcrumb: [
        {
          text: 'messages.navigationUpdate',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-navigation',
    name: 'AddNewNavigation',
    component: () => import('@/views/Admin/Navigations/add.vue'),
    meta: {
      pageTitle: 'messages.navigationNew',
      breadcrumb: [
        {
          text: 'messages.navigationNew',
          active: true,
        },
      ],
    },
  },
  // Navigations End
  // Customers
  {
    path: '/',
    name: 'customers',
    component: () => import('@/views/Admin/Customers/list.vue'),
    meta: {
      pageTitle: 'navigations.customers',
      breadcrumb: [
        {
          text: 'navigations.customers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customers/:id',
    name: 'CustomerDetail',
    component: () => import('@/views/Admin/Customers/detail.vue'),
    meta: {
      pageTitle: 'messages.customerDetail',
      breadcrumb: [
        {
          text: 'messages.customerDetail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-customer',
    name: 'AddNewCustomer',
    component: () => import('@/views/Admin/Customers/add.vue'),
    meta: {
      pageTitle: 'messages.customerNew',
      breadcrumb: [
        {
          text: 'messages.customerNew',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customers/:id/edit',
    name: 'EditCustomer',
    component: () => import('@/views/Admin/Customers/edit.vue'),
    meta: {
      pageTitle: 'messages.customerUpdate',
      breadcrumb: [
        {
          text: 'messages.customerUpdate',
          active: true,
        },
      ],
    },
  },
  // Customers End
  // Employees
  {
    path: '/employees',
    name: 'employees',
    component: () => import('@/views/Admin/Employees/list.vue'),
    meta: {
      pageTitle: 'navigations.employees',
      breadcrumb: [
        {
          text: 'navigations.employees',
          active: true,
        },
      ],
    },
  },
  {
    path: '/employees/:id',
    name: 'EmployeeDetail',
    component: () => import('@/views/Admin/Employees/detail.vue'),
    meta: {
      pageTitle: 'messages.employeeDetail',
      breadcrumb: [
        {
          text: 'messages.employeeDetail',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-employee',
    name: 'AddNewEmployee',
    component: () => import('@/views/Admin/Employees/add.vue'),
    meta: {
      pageTitle: 'messages.employeeNew',
      breadcrumb: [
        {
          text: 'messages.employeeNew',
          active: true,
        },
      ],
    },
  },
  {
    path: '/employees/:id/edit',
    name: 'EditEmployee',
    component: () => import('@/views/Admin/Employees/edit.vue'),
    meta: {
      pageTitle: 'messages.employeeUpdate',
      breadcrumb: [
        {
          text: 'messages.employeeUpdate',
          active: true,
        },
      ],
    },
  },
  // Employees End
  // Resorts
  {
    path: '/resorts',
    name: 'resorts',
    component: () => import('@/views/Admin/Resorts/list.vue'),
    meta: {
      pageTitle: 'navigations.resorts',
      breadcrumb: [
        {
          text: 'navigations.resorts',
          active: true,
        },
      ],
    },
  },
  {
    path: '/resorts/:id/edit',
    name: 'EditResort',
    component: () => import('@/views/Admin/Resorts/edit.vue'),
    meta: {
      pageTitle: 'messages.resortUpdate',
      breadcrumb: [
        {
          text: 'messages.resortUpdate',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-resort',
    name: 'AddNewResort',
    component: () => import('@/views/Admin/Resorts/add.vue'),
    meta: {
      pageTitle: 'messages.resortNew',
      breadcrumb: [
        {
          text: 'messages.resortNew',
          active: true,
        },
      ],
    },
  },
  // Resorts End
  // Coupons
  {
    path: '/coupons',
    name: 'coupons',
    component: () => import('@/views/Admin/Coupons/list.vue'),
    meta: {
      pageTitle: 'navigations.coupons',
      breadcrumb: [
        {
          text: 'navigations.coupons',
          active: true,
        },
      ],
    },
  },
  {
    path: '/coupons/:id/edit',
    name: 'EditCoupon',
    component: () => import('@/views/Admin/Coupons/edit.vue'),
    meta: {
      pageTitle: 'messages.couponUpdate',
      breadcrumb: [
        {
          text: 'messages.couponUpdate',
          active: true,
        },
      ],
    },
  },
  {
    path: '/add-coupon',
    name: 'AddNewCoupon',
    component: () => import('@/views/Admin/Coupons/add.vue'),
    meta: {
      pageTitle: 'messages.couponNew',
      breadcrumb: [
        {
          text: 'messages.couponNew',
          active: true,
        },
      ],
    },
  },
  // Coupons End
  // Invoices
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('@/views/Admin/Invoices/list.vue'),
    meta: {
      pageTitle: 'navigations.invoices',
      breadcrumb: [
        {
          text: 'navigations.invoices',
          active: true,
        },
      ],
    },
  },
  // Invoices End
  // Vouchers
  {
    path: '/vouchers',
    name: 'vouchers',
    component: () => import('@/views/Admin/Vouchers/list.vue'),
    meta: {
      pageTitle: 'Vouchers',
      breadcrumb: [
        {
          text: 'Vouchers',
          active: true,
        },
      ],
    },
  },
  // Vouchers end
  // User profile
  {
    path: '/profile',
    name: 'EditProfile',
    component: () => import('@/views/Admin/UserProfile/edit.vue'),
    meta: {
      pageTitle: 'messages.userUpdate',
      breadcrumb: [
        {
          text: 'messages.userUpdate',
          active: true,
        },
      ],
    },
  },
  // Variables
  {
    path: '/variables',
    name: 'variables',
    component: () => import('@/views/Admin/Variables/list.vue'),
    meta: {
      pageTitle: 'navigations.variables',
      breadcrumb: [
        {
          text: 'navigations.variables',
          active: true,
        },
      ],
    },
  },
  {
    path: '/variables/:id/edit',
    name: 'EditVariable',
    component: () => import('@/views/Admin/Variables/edit.vue'),
    meta: {
      pageTitle: 'variable.update',
      breadcrumb: [
        {
          text: 'variable.update',
          active: true,
        },
      ],
    },
  },
  // Variables End
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  // Redirect to login if not logged in
  if (!isLoggedIn && (to.name !== 'login' && to.name !== 'forgot-password')) {
    return next({name: 'login'})
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.roles[0] : null))
  }

  // Router guards
  if (isLoggedIn) {
    /* const userData = getUserData()
    // Admin
    if (userData.role === 'ROLE_ADMIN' && ([].includes(to.name))) {
      next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    } */
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
