
import moment from 'moment'
import enumsEn from '@/locales/enum-en.json'
import enumsSk from '@/locales/enum-sk.json'
import {getUserRoles} from "@/auth/utils";

export const isGranted = action => {
  let role = ('ROLE_PRIVILEGE_'+action).toUpperCase()
  return getUserRoles().includes(role) || getUserRoles().includes('ROLE_ADMIN')
}

const slugify = txt => {
    let str = txt
    str = str.replace(/^\s+|\s+$/g, '')

    // Make the string lowercase
    str = str.toLowerCase()

    // Remove accents, swap ñ for n, etc
    const from = 'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;'
    const to = 'AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa______'
    for (let i = 0, l = from.length; i < l; i += 1) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }

    // Remove invalid chars
    str = str.replace(/[^a-z0-9 -]/g, '')
        // Collapse whitespace and replace by _
        .replace(/\s+/g, '_')
        // Collapse dashes
        .replace(/-+/g, '_')

    return str
}

const slugifyURL = txt => {
    let str = txt
    str = str.replace(/^\s+|\s+$/g, '')

    // Make the string lowercase
    str = str.toLowerCase()

    // Remove accents, swap ñ for n, etc
    const from = 'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽĹĽľĺáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/-,:;'
    const to = 'AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZLLllaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------'
    for (let i = 0, l = from.length; i < l; i += 1) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }

    // Remove invalid chars
    str = str.replace(/[^a-z0-9 -]/g, '')
        // Collapse whitespace and replace by _
        .replace(/\s+/g, '-')
        // Collapse dashes
        .replace(/-+/g, '-')

    return str
}

const generateRandomString = length => {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}

const showToast = (toast, toastComponent, title, text, variant) => {
    toast({
        component: toastComponent,
        position: 'top-right',
        props: {
            title,
            text,
            variant,
        },
    })
}

const getEnumTranslation = (name, key, lang = null) => {

    let enums = enumsEn
    if(lang) {
        if (lang === 'sk') {
            enums = enumsSk
        }
        if (lang === 'en') {
            enums = enumsEn
        }
    }

    if (enums[name]) {
        if (enums[name][key]) {
            return enums[name][key]
        }
    } else if (enums[key]) {
        return enums[key]
    }
    return null
}

const getAddUrl = (root) => {
    return `/add-${root}`;
}

const getDetailUrl = (root, id) => {
    return `/${root}s/${id}`;
}

const getEditUrl = (root, id) => {
    return `/${root}s/${id}/edit`;
}


const formatDate = date => moment(date).format('DD.MM.YYYY')

const formatTime = date => moment(date).format('HH:mm')

export default {
    slugify,
    slugifyURL,
    showToast,
    getEnumTranslation,
    generateRandomString,
    formatDate,
    formatTime,
    getAddUrl,
    getDetailUrl,
    getEditUrl
}
